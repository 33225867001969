import { Sync } from '@egjs/flicking-plugins';
import Flicking from '@egjs/react-flicking';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import type { SyncOptions } from '@egjs/flicking-plugins';

import { ArrowButtonSize } from '~/components/Buttons/Types/constants';
import { GalleryCaption } from '~/components/Gallery/GalleryCaption';
import { GalleryCounter } from '~/components/Gallery/GalleryCounter';
import { GalleryFullscreen } from '~/components/Gallery/GalleryFullscreen';
import { GallerySlider } from '~/components/Gallery/GallerySlider';
import { ALIGN, DIRECTION } from '~/components/Slider/Slider.constants';
import { GallerySliderPanelChildren } from '~/components/Gallery/GallerySliderPanelChildren';
import { SliderArrow } from '~/components/Slider/SliderArrow';
import { SliderCounterOverlay } from '~/components/Slider/SliderCounterOverlay';
import { SliderDots } from '~/components/Slider/SliderDots';

import type { GalleryProps } from '~/components/Gallery/Gallery.types';

import styles from '~/components/Gallery/Gallery.module.scss';

export const Gallery = (props: GalleryProps) => {
	const {
		captionSizeXSonMobile = false,
		dataTrLinkEventTrack = false,
		defaultIndex = 0,
		defaultSliderHeight,
		flickingRef: propsFlickingRef,
		isFullscreen,
		magicModal,
		parentFlickingRef,
		showCaption,
		showCaptionBelowImageSm,
		showControlsArrows,
		showCounter,
		showDots,
		showFullscreenSm,
		showSliderArrows,
		showSliderArrowsIndented,
		showSliderArrowsSm,
		showSliderCounter,
		showSliderPanelChildren,
		showSliderPanelChildrenBelowSm,
		sliderArrowOnClick,
		sliderDotsOnClick,
		sliderArrowProps: {
			className: arrowClassName = '',
			size = ArrowButtonSize.Medium
		} = {},
		sliderDotClassName,
		sliderDotsProps,
		sliderPanels,
		sliderProps,
		sliderPropsByBreakpoints,
	} = props;

	const [plugins, setPlugins] = useState<Partial<SyncOptions>[]>([]);

	const flickingRef = propsFlickingRef || useRef<Flicking>(null);

	const sliderDotsFlickingRef = useRef<Flicking>(null);

	const fullscreenRef = useRef<HTMLButtonElement>(null);

	const panelCount = sliderPanels.length;

	const hasMultiplePanels = panelCount > 1;

	useEffect(() => {
		if (flickingRef.current && sliderDotsFlickingRef.current) {
			setPlugins([
				new Sync({
					type: 'index',
					synchronizedFlickingOptions: [
						{
							flicking: flickingRef.current,
							isSlidable: true,
						},
						{
							flicking: sliderDotsFlickingRef.current,
							isSlidable: true,
						},
						...(parentFlickingRef?.current ? [{
							flicking: parentFlickingRef.current,
							isSlidable: true,
						}] : []),
					]
				})
			]);
		}
	}, []);

	if (!panelCount) {
		return null;
	}

	return (
		<div
			className={
				classNames(styles.gallery, {
					[styles.galleryFullscreen]: isFullscreen,
				})
			}
			data-qa={isFullscreen ? 'gallery-fullscreen' : 'gallery'}
		>
			<div className={styles.gallerySliderContainer}>
				{showSliderArrows && hasMultiplePanels && (
					<div
						className={
							classNames(styles.gallerySliderArrowPrev, {
								'tw-hidden': !showSliderArrowsSm,
								[arrowClassName]: arrowClassName,
							})
						}
					>
						<SliderArrow
							size={size}
							direction={DIRECTION.PREV}
							flickingRef={flickingRef}
							onClick={sliderArrowOnClick}
							dataTrLinkEventTrack={dataTrLinkEventTrack}
						/>
					</div>
				)}
				<GallerySlider
					defaultSliderHeight={defaultSliderHeight}
					flickingRef={flickingRef}
					plugins={plugins}
					showSliderArrowsIndented={showSliderArrowsIndented}
					showSliderPanelChildren={!isFullscreen && showSliderPanelChildren}
					showSliderPanelChildrenBelowSm={showSliderPanelChildrenBelowSm}
					sliderPanels={sliderPanels}
					sliderProps={{
						...sliderProps,
						defaultIndex,
					}}
					sliderPropsByBreakpoints={sliderPropsByBreakpoints}
				/>
				{showSliderCounter && (
					<SliderCounterOverlay
						align={isFullscreen ? ALIGN.LEFT : ALIGN.RIGHT}
						defaultIndex={defaultIndex}
						flickingRef={flickingRef}
						panelCount={panelCount}
					/>
				)}
				{showSliderArrows && hasMultiplePanels && (
					<div
						className={
							classNames(styles.gallerySliderArrowNext, {
								'tw-hidden': !showSliderArrowsSm,
								[arrowClassName]: arrowClassName,
							})
						}
					>
						<SliderArrow
							size={size}
							direction={DIRECTION.NEXT}
							flickingRef={flickingRef}
							onClick={sliderArrowOnClick}
							dataTrLinkEventTrack={dataTrLinkEventTrack}
						/>
					</div>
				)}
			</div>
			{showCaption && showCaptionBelowImageSm && (
				<div className={classNames({
					'tw-block md:tw-hidden': showCaptionBelowImageSm,
				})}>
					<GalleryCaption
						className={captionSizeXSonMobile ? 'tw-text-xs tw-text-gray-300' : ''}
						defaultIndex={defaultIndex}
						fixedHeight={isFullscreen}
						flickingRef={flickingRef}
						sliderPanels={sliderPanels}
					/>
				</div>
			)}
			{!isFullscreen && showSliderPanelChildren && showSliderPanelChildrenBelowSm && (
				<GallerySliderPanelChildren
					defaultIndex={defaultIndex}
					flickingRef={flickingRef}
					sliderPanels={sliderPanels}
				/>
			)}
			<div className={styles.galleryControls}>
				{showControlsArrows && hasMultiplePanels && (
					<div className={styles.galleryControlsArrowPrev}>
						<SliderArrow
							direction={DIRECTION.PREV}
							flickingRef={flickingRef}
							onClick={sliderArrowOnClick}
						/>
					</div>
				)}
				<div className={styles.galleryControlsDotsCounter}>
					{showDots && hasMultiplePanels && (
						<div className={styles.galleryControlsDots}>
							<SliderDots
								panelCount={panelCount}
								onClick={sliderDotsOnClick}
								sliderDotClassName={sliderDotClassName}
								sliderProps={{
									...sliderDotsProps,
									defaultIndex,
									trLinkEventTrack: dataTrLinkEventTrack,
								}}
								ref={sliderDotsFlickingRef}
							/>
						</div>
					)}
					{showCounter && hasMultiplePanels && (
						<GalleryCounter
							defaultIndex={defaultIndex}
							flickingRef={flickingRef}
							panelCount={panelCount}
						/>
					)}
					{showFullscreenSm && (
						<div className="md:tw-hidden">
							<GalleryFullscreen
								onClick={
									() => {
										const fullscreenProps = {
											...props,
											defaultIndex: flickingRef.current?.index,
											flickingRef: undefined,
											isFullscreen: true,
											parentFlickingRef: flickingRef,
											showFullscreenSm: false,
											showSliderArrows: true,
											showSliderArrowsSm: true,
										};

										magicModal?.openModal({
											absoluteScrollTop: 0,
											content: {
												children: (
													<Gallery {...fullscreenProps} />
												),
											},
											height: '100vh',
											onCloseFocusElement: fullscreenRef.current,
											showHeader: false,
											title: 'Fullscreen media',
											width: '100vw',
										});
									}
								}
								ref={fullscreenRef}
							/>
						</div>
					)}
				</div>
				{showControlsArrows && hasMultiplePanels && (
					<div className={styles.galleryControlsArrowNext}>
						<SliderArrow
							direction={DIRECTION.NEXT}
							flickingRef={flickingRef}
							onClick={sliderArrowOnClick}
						/>
					</div>
				)}
			</div>
			{showCaption && (
				<GalleryCaption
					className={classNames({
						'tw-hidden md:tw-block': showCaptionBelowImageSm,
						'tw-text-xs tw-text-gray-300': captionSizeXSonMobile
					})}
					defaultIndex={defaultIndex}
					fixedHeight={isFullscreen}
					flickingRef={flickingRef}
					sliderPanels={sliderPanels}
				/>
			)}
		</div>
	);
};
