import React from 'react';

import { useStoreContext } from '~/locations/Contexts/StorePages.context';
import { AboutUs } from '~/locations/Components/AboutUs';
import Breadcrumbs from '~/layout/breadcrumbs';
import { CuralateLocation } from '~/locations/Components/CuralateLocation';
import { DCSchema } from '~/locations/Components/DCSchema';
import { DeliveryCenterDetails } from '~/locations/Components/DeliveryCenterDetails';
import { ExploreCareers } from '~/locations/Components/ExploreCareers';
import { Favorites } from '~/locations/Components/favorites/Favorites';
import { FreeDesignServices } from '~/locations/Components/FreeDesignServices';
import Head from '~/layout/head/Components/Head';
import { LocationDetails } from '~/locations/Components/LocationDetails';
import { s7ImagePath } from '~/global/global.constants';
import { StoreDetails } from '~/locations/Components/StoreDetails';
import { StoreGallery } from '~/locations/Components/StoreGallery';
import { StoreSchema } from '~/locations/Components/StoreSchema';
import { VirtualTour } from '~/locations/Components/VirtualTour';
import { NotFound } from '~/NotFound.page';
import { Events } from '~/locations/Components/Events';
import { DivideYWrapper } from '~/components/divide-y-wrapper/DivideYWrapper';

export const StandardStore = () => {
	const {
		model: {
			breadcrumb,
			dcKey,
			galleryItems,
			imageName,
			isNewStore,
			noGallery,
			key,
			metaDescription,
			storeName,
		} = {},
		error,
	} = useStoreContext();

	if (error) {
		console.error(error);
		return (
			<NotFound />
		);
	}

	return (
		<div className="store" data-tr-link-event-comp-name={storeName}>
			<Head
				title={`Modern Furniture Store in ${storeName} - Room & Board`}
				description={metaDescription}
				ogImage={`${s7ImagePath}/${imageName}?size=1200,500&scl=1`}
			/>
			{breadcrumb &&
				<div className="tw-pb-2">
					<Breadcrumbs breadcrumbModels={breadcrumb} />
				</div>
			}
			<StoreDetails />
			<DivideYWrapper classNames="print:tw-divide-y-0">
				<LocationDetails />
				<StoreSchema />
				{!isNewStore &&
				<>
					{key !== 'outlet' &&
					<>
						<FreeDesignServices />
						<Events />
						<VirtualTour />
					</>
					}
					<div>
						{!noGallery && (
							<StoreGallery
								galleryItems={galleryItems}
								storeName={storeName}
							/>
						)}
						<AboutUs />
					</div>
					{key !== 'outlet' &&
					<>
						<Favorites />
						<CuralateLocation />
						{dcKey &&
							<DeliveryCenterDetails />
						}
						<DCSchema />
					</>
					}
				</>
				}
				<ExploreCareers />
			</DivideYWrapper>
		</div>
	);
};
