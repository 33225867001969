import React, { createContext, useContext } from 'react';

import { BreadcrumbModelFactory } from '~/layout/Models/Breadcrumb.model';
import { DeliveryCenterModelFactory } from '~/locations/Models/DeliveryCenter.model';
import { storeData, deliveryCenterData } from '~/locations/Data/storeData';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { curalateStoresDataSourceId, curalateStoresFanreelId } from '~/global/global.constants';
import { CuralateStoreFactory } from '~/util/curalate/Stores/Curalate.store';
import { storeLocationsData } from '~/locations/Data/storeLocationsData';
import { StoreModelFactory } from '~/locations/Models/Store.model';
import { EventFactory } from '~/locations/Models/Event.model';
import { EventRSVPFactory } from '~/locations/Models/EventRSVP.model';

const storeContext = createContext();

export const StoreContextProvider = (props) => {
	const {
		magicModal,
		cookies,
		featureTogglesModel,
	} = useGlobalContext();

	const {
		children,
		pageProps: {
			model: pageModel = {},
			model: {
				query: {
					store = '',
				},
				events = [],
			} = {},
		} = {},
	} = props;

	const storeJson = storeData[store];

	if (storeJson) {
		const deliveryCenterJson = deliveryCenterData[storeJson.location.deliveryCenter];
		const model = StoreModelFactory.create(pageModel);

		model.breadcrumb = BreadcrumbModelFactory.create(storeLocationsData, model.breadcrumbID);

		model.events = events.map((event) => {
			const eventModel = EventFactory.create(event);
			if (event.rsvp) {
				eventModel.rsvp = EventRSVPFactory.create(event.rsvp);
			}
			return eventModel;
		});

		model.deliveryCenter = DeliveryCenterModelFactory.create(deliveryCenterJson);

		const filter = `label:${storeJson.location?.curalateLabel}`;
		const curalateStore = CuralateStoreFactory.create(cookies, magicModal, featureTogglesModel, {
			dataSourceId: curalateStoresDataSourceId,
			experienceId: 'product-page-experience',
			fanreelId: curalateStoresFanreelId,
			filter,
			limit: 50,
			itemsPerPage: 5,
		});

		const context = {
			curalateStore,
			model,
		};

		return <storeContext.Provider value={context}>{children}</storeContext.Provider>;
	}

	return <storeContext.Provider value={{ error: 'JSON data not found' }}>{children}</storeContext.Provider>;
};

export const useStoreContext = () => useContext(storeContext);
