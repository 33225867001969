import Flicking from '@egjs/react-flicking';
import React, { useEffect, useState } from 'react';

import type { WillChangeEvent } from '@egjs/react-flicking';
import type { RefObject } from 'react';

import { EVENTS } from '~/components/Slider/Slider.constants';
import { GallerySliderPanelData } from '~/components/Gallery/Gallery.types';

import styles from '~/components/MediaViewer/MediaViewerSliderPanelChildren.module.scss';

export const GallerySliderPanelChildren = ({
	defaultIndex = 0,
	flickingRef,
	sliderPanels,
}: {
	defaultIndex?: number,
	flickingRef: RefObject<Flicking>,
	sliderPanels: GallerySliderPanelData[],
}) => {
	const [currentIndex, setCurrentIndex] = useState(defaultIndex);

	const currentPanel = sliderPanels[currentIndex];

	const {
		sliderPanelComponentProps: {
			children,
		}
	} = currentPanel;

	function handleWillChange({ index }: WillChangeEvent) {
		setCurrentIndex(index);
	}

	useEffect(() => {
		flickingRef.current?.on?.(EVENTS.WILL_CHANGE, handleWillChange);

		return () => {
			flickingRef.current?.off?.(EVENTS.WILL_CHANGE, handleWillChange);
		};
	}, [flickingRef.current]);

	if (!children) {
		return null;
	}

	return (
		<div
			className={styles.mediaViewerSliderPanelChildren}
			data-qa="gallery-slider-panel-children"
		>
			{children}
		</div>
	);
};
